import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withPrefix } from "gatsby";
import classNames from 'classnames';
import BlueButton from "../../components/BlueButton";
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  container: {
    paddingTop: theme.spacing.unit
  },
  item: {
    paddingTop: theme.spacing.unit * 10
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3,
    fontSize: "20px",
    color: "#777777",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  iconHeading: {
    ...theme.typography.subheading,
    fontSize: "35px",
    color: "black",
    paddingTop: theme.spacing.unit * 3,
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
  },
  bluePara: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    lineHeight: "1.8",
    color: "rgba(0, 156, 219, 255)",
    fontWeight: "bold",
    fontSize: "16px"
  },
  margin: {
    marginBottom: theme.spacing.unit * 3,
  }
});

const Overview = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
      <TabBar fields={servicesFields} />
      <div className={classes.root}>
        <div className={classes.subheading}>Custom software development</div>
        <div className={classes.article}>
          for even the most persistent and seemingly impossible difficulties.
        </div>
        <div className={classes.paragraph}>
          Software engineering is the magic that makes business growth and
          innovation possible. But it can also be a roadblock, especially if
          your team isn’t familiar with the technologies you need to move your
          project from idea to reality, and more so if your team members don’t
          know what they don’t know. <br /> We at Primoko DO know what others
          don’t. And our engineers with various specialties are standing by to
          act as the fuel that will make your project go.
        </div>
        <Grid
          container
          justify="space-between"
          alignitems="center"
          className={classes.container}
        >
          <Grid item className={classes.item} sm={12} md={5}>
            <img
              className={classes.image}
              src={withPrefix("img/mobile.png")}
              width="33px"
              height="61px"
            />
            <Typography className={classes.iconHeading}>Mobile</Typography>
            <Typography className={classes.paragraph}>
              Stay ahead of the curve by adapting tomorrow’s technology for your
              mobile application. Experience includes work in the banking and
              luxury ecommerce domains.
            </Typography>
            <Typography className={classes.bluePara}>
              Technologies we've worked with:
              <br />
              iOS • Android • React Native • Swift • Objetive-C • Xcode
            </Typography>
            <BlueButton to="/services/mobile" text="See mobile Services" />
          </Grid>
          <Grid item className={classes.item} sm={12} md={5}>
            <img
              className={classes.image}
              src={withPrefix("img/web.png")}
              width="55px"
              height="55px"
            />
            <Typography className={classes.iconHeading}>Web</Typography>
            <Typography className={classes.paragraph}>
              Web apps that work will differentiate and drive your business.
              From coding specific solutions and solving technical problems to
              holding full ownership—from design and development to testing and
              ongoing maintenance—Primoko has you covered.
            </Typography>
            <Typography className={classes.bluePara}>
              Technology we’ve worked with:<br />
              Mongo DB • Express JS • Angular • Node JS • Linux • Apache •
              MySSQL • PHP • JQuery • React • AWS • Firebase
            </Typography>
            <BlueButton to="/services/web" text="See web Services" />
          </Grid>

          <Grid item className={classes.item} sm={12} md={5}>
            <img
              className={classes.image}
              src={withPrefix("img/devops.png")}
              width="71px"
              height="63px"
            />
            <Typography className={classes.iconHeading}>DevOps</Typography>
            <Typography className={classes.paragraph}>
              Imagine feedback cycles measured in hours instead of weeks.
              Imagine simplicity, performance, and speed. Imagine automation
              wherever it fits. We are big fans of DevOps, especially of
              automating it as much as possible.
            </Typography>
            <Typography className={classes.bluePara}>
              Technology we’ve worked with:<br />
              Jenkins • CruiseControl • AWS • Docker • Maven • Ant • Git • SVN
            </Typography>
            <BlueButton to="/services/devops" text="See devops Services" />
          </Grid>
          <Grid item className={classes.item} sm={12} md={5}>
            <img
              className={classes.image}
              src={withPrefix("img/salesforce.png")}
              width="104px"
              height="60px"
            />
            <Typography className={classes.iconHeading}>Salesforce</Typography>
            <Typography className={classes.paragraph}>
              Build a unified view of your customer with a new Salesforce
              integration and implementation. Or, breath new life into an
              existing implementation.
            </Typography>
            <Typography className={classes.bluePara}>
              Technology we’ve worked with:<br />
              Salesforce Commerce
            </Typography>
            <BlueButton to="/services/salesforce" text="See salesforce Services" />
          </Grid>
          <Grid item className={classes.item} sm={12} md={5}>
            <img
              className={classes.image}
              src={withPrefix("img/humanoko.png")}
              width="276px"
              height="42px"
            />
            <Typography className={classes.iconHeading}>Staffing</Typography>
            <Typography className={classNames(classes.paragraph, classes.margin)}>
              Need brilliant talent for your organization? Find elite software
              engineers the same caliber as those that work for Primoko by
              connecting with the same recruiters we use at our sister company,
              Humanoko.
              <br />Backstory: we created Humanoko after our clients, who had
              been bombarding us with requests to find elite engineers, began
              trying to poach ours! That’s when we knew it was time to create a
              recruitment firm specializing in bringing forward-thinking
              companies and brilliant software engineers together; that’s when
              we launched Humanoko.
            </Typography>
            <BlueButton to="https://humanoko.com" text="Humanoko" />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Overview);
