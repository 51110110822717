import React, { Component } from "react";
import Overview from "../../containers/services/Overview";
import { graphql } from 'gatsby';

export default class ServicesIndexPagePreviewTemplate extends Component {
  render() {
    const { data } = this.props;
    const { frontmatter } = data.markdownRemark;

    return <Overview data={frontmatter} />;
  }
}
export const pageQuery = graphql`
  query ServicesIndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        sepratorImage
        heading
        description
        humanoko {
          heading
          button
          image
          description
        }
        testimonials {
          author
          authorRole
          quote
        }
        technologies {
          heading
          tags
          helperText
          buttonText
          link
          description
        }
      }
    }
  }
`;
